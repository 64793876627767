.title {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 32px;
  margin: 0px;
}

.title-wrapper-2 {
  display: flex;
  margin-bottom: 30px;
  align-items: center;
}

.icon-wrapper {
  margin-right: 16px;
}

.component-card-2 {
  background-color: white;

  height: 420px;
  border-radius: 10px;

  box-shadow: 0px 12px 24px rgba(221, 221, 221, 0.4);
  padding: 20px;
}

.subtitle {
  text-align: left;
  font-family: 'Poppins';

  font-weight: 500;
  font-size: 16px;

  display: flex;
  align-items: center;

  margin-bottom: 35px;
  color: #9e9e9e;
}

.item-container-2 {
  width: 100%;
  margin-bottom: 60px;
}

.list-item {
  font-family: 'Poppins';

  font-weight: 500;
  font-size: 16px;
  padding-bottom: 20px;
  padding-left: 10px;
  text-align: left;
  color: #121212;
}

.button-view {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.subscription-item {
  width: 100%;

  border-radius: 10px;
  padding: 10px 20px;
  border: solid 1px #c4c4c4;
}

.subscription-item-text {
  font-family: 'Poppins';

  font-weight: 500;
  font-size: 14px;
  padding: 0px;
  /* identical to box height */

  text-align: left;
  letter-spacing: -0.3px;

  /* brand colour */
  margin: 2px;
  color: #56bd82;
}

.subscription-item-sub {
  font-family: 'Poppins';

  font-weight: 500;
  font-size: 14px;
  padding: 0px;
  padding-left: 2px;
  margin: 0px;
  /* identical to box height */

  text-align: left;
  letter-spacing: -0.3px;

  /* brand colour */
}
