.new-button-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin: 0px;
  margin-left: 0px;
  line-height: 18px;
  /* identical to box height */

  text-align: center;
  letter-spacing: -0.3px;

  color: #56bd82;
}

.new-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #56bd82;
  box-sizing: border-box;
  border-radius: 39px;

  padding: 6px 18px;
  align-self: center;
}

.new-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 25px ;
}
