.title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 32px;
}
.cat {
  height: auto;
}

.title-wrapper {
  display: flex;
}

.icon-wrapper {
  margin-right: 16px;
}

.component-card-5 {
  background-color: white;
  width: 99%;
  height: 420px;
  border-radius: 10px;
  border: 0.5px solid #86d0a6;
  box-shadow: 0px 12px 24px rgba(221, 221, 221, 0.4);
  padding: 20px;
}

.subtitle {
  text-align: left;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  color: #9e9e9e;
}

.item-container-5 {
  width: 100%;
  margin-left: 10px;
  margin-bottom: 60px;

}
