.title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 32px;
  margin: 0px;
}

.title-wrapper {
  display: flex;
  margin-bottom:30px;
  align-items: center;
}

.icon-wrapper {
  margin-right: 16px;

}

.icon {
  width: 50px;
  height: 50px;
}
.component-card {
  background-color: white;
  width: 100%;
  height: 420px;
  border-radius: 10px;
  box-shadow: 0px 12px 24px rgba(221, 221, 221, 0.4);
  padding: 20px;
}

.subtitle {
  text-align: left;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #9e9e9e;
}

.item-container {
  width: 95%;
  margin-left: 10px;
  margin-bottom: 60px;
}

.component-card-body {
  height: 250px;
}
