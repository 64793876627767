.bank-img {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  margin-right: 30px;
  filter: drop-shadow(0px 0px 0px rgba(48, 48, 48, 0.25));
  border: 2px solid ;
}

.account-item-container {
  display: flex;
  justify-content:flex-start;
  align-items: center;
  text-align: left;
}

.inner-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  margin: 0px;
  text-align: left;
  margin-right: 36px;
}

.bank-name {
  width: 100px;
}

.account-number {

}

.account-name{
  width: 200px;
}

.balance {
  font-family: "Poppins";
  font-style: normal;
    font-size: 14px;
    font-weight: 500;
    color: #10CD45;
    margin-right: 10px;
    width: 120px;
}