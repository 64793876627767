.category-image-container {
  height: 60px;
  width: 60px;
  border-radius: 40px;
  background: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.category-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  text-align: left;
  letter-spacing: 0.2px;

  color: #121212;
}
