.text-input-outer {
  margin-bottom: 25px;
  text-align: left;
}

.error-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
padding-top: 2px;
  color: red;
}

.text-input-wrapper {
  background: #f4f4f4;
  border: 2px solid #f4f4f4;
  box-sizing: border-box;
  padding: 8px 2px;
 
  border-radius: 40px;
  align-items:center;
}
.text-input-container {

  width: 90%;
  align-items: flex-start;
}

.input-label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  /* identical to box height */

  text-align: center;
  letter-spacing: -0.3px;

  color: #9e9e9e;
}

.input-box {
  background-color: rgba(0, 0, 0, 0);
  outline: none !important;
  border: none;
width: 90%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  letter-spacing: -0.3px;

  color: #121212;
}

.input-box:focus {
  outline: none !important;
  border: red;
}

.input-box::-webkit-input-placeholder {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  letter-spacing: -0.3px;

  color: #c4c4c4;

}

