.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.content-body {
  transform: translateY(-220px);
}

.footer-container {
  margin-top: 20px;
  margin-bottom: 10px;

  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 200;
  font-size: 18px;
  line-height: 27px;
  color: #9e9e9e;
}

.footer-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #9e9e9e;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



/* Responsiveness */

/* Large Screen */

@media only screen and (max-width: 1450px) {
}

/* Laptop  */
@media only screen and (max-width: 1200px) {

  .item-container-5 {
   
    margin-left: 0px;
    
  
  }


  .item-container {
    width: 100%;
    margin-left: 0px;

    margin-bottom: 60px;
  }

  .item-container-2 {
    margin-bottom: 60px;
  }

  .account-item-container {
    justify-content: space-between;
    padding-right: 50px;
  }

  .bank-img {
    margin-right: 10px;
  }

  .bank-name {
    width: 120px;
  }

  .inner-text {
    margin-right: 0px;
  }

  .account-name {
    width: 150px;
  }

  .balance {
    margin-right: 0px;
    width: 130px;
  }

  .new-button-wrapper {
    padding-top: 35px;
  }

  .new-button-container {
    width: 82px;
  }

  .component-card-5 {
    width: 100%;
  }
}

/* Tablet */

@media only screen and (max-width: 1000px) {
  .title {
    font-size: 25px;
  }

  .title-wrapper {
    margin-bottom: 19px;
  }

  .title-wrapper-2 {
    margin-bottom: 19px;
  }

  .subtitle {
    font-size: 14px;
  }

  .icon {
    width: 42px;
    height: 42px;
  }
  .icon-wrapper {
    margin-right: 10px;
  }
  .inner-text {

    font-size: 13px;
 
  }

  .bank-name {
    width: 75px;
}
  
.sub-card {
  height: auto;
}

}

/* Mobile L */

@media only screen and (max-width: 550px) {

  .title {
    font-size: 22px;
  }

  .title-wrapper {
    margin-bottom: 15px;
  }

  .title-wrapper-2 {
    margin-bottom: 15px;
  }

  .subtitle {
    font-size: 13px;
  }

  .icon {
    width: 40px;
    height: 40px;
  }
  .icon-wrapper {
    margin-right: 10px;
  }
  .inner-text {

    font-size: 13px;
 
  }

  .bank-name {
    width: 90px;
}
.account-name{
 display : none;
}

.balance { 
  display : none;
}


.account-item-container {
  justify-content: space-between;
  padding-right: 20px;
}


.footer-text {

  font-size: 14px;
}

.list-item {
  font-size: 14px;
}



}

/* Mobile M */

@media only screen and (max-width: 380px) {
  .inner-text {

    font-size: 11px;
 
  }

  .list-item {
    font-size: 13px;
  }

  .icon {
    width: 40px;
    height: 40px;
  }

  .bank-img {
    width: 45px;
    height: 45px;

  }
  .title {
    font-size: 20px;
  }


  .subtitle {
    font-size: 12px;
  }

}

/* Mobile S */

@media only screen and (max-width: 330px) {

  .title {
    font-size: 18px;
  }

  .icon {
    width: 38px;
    height: 38px;
  }


}
