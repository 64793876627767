.button-container {
  border: none;
  width: 309px;
  height: 50px;

  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */

  text-align: center;
  letter-spacing: -0.3px;

  color: #fafafa;
}
